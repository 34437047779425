@use '../../../variables.scss' as v;

.__COMPONENT__list {
  > .container {
    margin-top: 4rem;

    h3 {
      font-size: 2.4rem;
      color: v.$GRAY_PRIMARY_DARK;
      text-transform: uppercase;
    }

    > hr {
      height: .5rem;
      border-style: double;
      border-left: none;
      border-right: none;
    }

    ul {
      li {
        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          
          h4 {

          }

          .date-tag {
            margin-left: 1rem;
            padding: .25rem .5rem;

            font-size: 1.2rem;
            font-weight: 700;
            color: v.$WHITE_PRIMARY_LIGHT;
            background-color: v.$BLACK_PRIMARY_DARK;
            white-space: nowrap;
          }
        }

        p {
          color: v.$GRAY_PRIMARY_DARK;
        }

        > hr {
          margin: 2rem 0;
          border: none;
          border-top: .1rem solid v.$GRAY_PRIMARY_LIGHT;
        }

        .formless-container {
          > * {
            display: flex;
          }

          span {
            margin-bottom: 1rem;
            font-weight: 700;

            &:not(:first-of-type) {
              margin-top: 2rem;
            }
          }

          a {
            &:not(:first-of-type) {
              margin-top: 1rem;
            }
          }

          .images {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;            

            img {
              max-width: 100%;
              object-fit: contain;
              border: .1rem solid v.$GRAY_PRIMARY_LIGHT;
              box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.10);

              @include v.DESKTOP {
                max-width: calc(50% - 1rem);
              }
            }
          }
        }

        &:last-of-type {
          hr {
            display: none;
          }
        }
      }
    }
  }
}