@use 'variables.scss' as v;

@font-face {
  font-family: 'GT Eesti';
  src: local('GT Eesti'), url('../public/fonts/gt_eesti_display_regular.otf') format('opentype');
}

@font-face {
  font-family: 'GT Eesti';
  src: local('GT Eesti'), url('../public/fonts/gt_eesti_display_bold.otf') format('opentype');
  font-weight: bold;
}

html {
  font-size: 67.5%
}

body {
  margin: 0;
  font-family: 'GT Eesti';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  margin: 0;
  line-height: 1;
  word-break: break-word;
  hyphens: auto
}

p {
  line-height: 1.5;
}

a {
  color: v.$BLACK_PRIMARY_DARK;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

button {
  opacity: .9;

  min-width: 10rem;
  height: 3rem;
  padding: .5rem 1rem;

  border: none;
  background-color: v.$BLACK_PRIMARY_DARK;
  color: v.$WHITE_PRIMARY_LIGHT;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;

  transition-duration: 250ms;

  &:hover, &:active {
    opacity: 1;
    box-shadow: 
      0 0 1rem rgba(0, 0, 0, 0.25);
  }
}
