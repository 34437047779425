@use '../../variables.scss' as v;

@mixin profileImg {
  margin-bottom: 2rem;
  object-fit: contain;

  border-radius: .5rem;
  border: .5rem solid v.$WHITE_PRIMARY_LIGHT;
  box-shadow: 
    0 .5rem 1rem rgba(0, 0, 0, 0.10),
    0 2rem 5rem rgba(0, 0, 0, 0.15);

  @include v.MOBILE_AND_TABLET {
    width: 15rem;
    height: 15rem;
  }

  @include v.DESKTOP {
    width: 20rem;
    height: 20rem;
  }
}

// PDF ONLY CLASS
.__CV_PDF__ {
  header {
    display: flex;
    justify-content: center;

    .profile-img {
      @include profileImg;
      margin-top: 12rem;
      // box-shadow not supported in html2pdf plugin; so using alternative styling for pdf
      border-color: v.$GRAY_PRIMARY_LIGHT;
      border-radius: 0;
    }
  }

  .table-list {
    // Note: all elements within the .formless-container class automatically get display: flex. See list.style.scss
    justify-content: space-between;
  
    ul {
      font-size: 1.6rem;
      
      &:first-of-type {
        li {
          font-weight: 700;
        }
      }
  
      li {
        &:not(:first-of-type) {
          margin-top: 1rem;
        }
      }
    }
  }
}
// ^

// APP COMPONENT
.__COMPONENT__app {
  > .container {
    margin-top: 8rem;
    margin-bottom: 4rem;

    @include v.MOBILE_AND_TABLET {
      margin-top: 2rem;
    }

    @include v.CONTENT {
      .content-container {
        display: flex;
        gap: 3rem;
  
        @include v.MOBILE_AND_TABLET {
          flex-direction: column;
        }
  
        .column {
          h1 {
            font-weight: 700;
            font-size: 6rem;
          }
  
          h2 {
            font-weight: 700;
            font-size: 3.6rem;
          }
  
          &.left {
            display: flex;
            flex-direction: column;         

            @include v.MOBILE_AND_TABLET {
              width: 100%;
              align-items: center;
            }

            @include v.DESKTOP {
              width: 33%;
            }

            .profile-img {
              @include profileImg;
            }

            p {
              font-style: italic;
            }            
  
            .social-media-container {
              display: flex;
              align-items: center;
              gap: 2rem;
  
              .icons {
                display: flex;
                gap: .5rem;
  
                a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 4rem;
                  height: 4rem;
                  background-color: v.$BLACK_PRIMARY_DARK;
                  border-radius: 50%;

                  img {
                    height: 1.5rem;
                    object-fit: contain;
                  }
                }
              }
            }
  
            button {
              width: 100%;

              &:not(:first-of-type) {
                margin-top: 1rem;
              }

              &:nth-of-type(3) {
                position: relative;
                background: linear-gradient(135deg, v.$YELLOW_PRIMARY_LIGHT, v.$PURPLE_PRIMARY_LIGHT);

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  padding-top: .75rem;
                  
                  display: block;
                  width: 100%;
                  height: 100%;
                  text-decoration: none;
                  color: v.$WHITE_PRIMARY_LIGHT;
                }
              }

              @include v.MOBILE {
                margin-top: 2rem;
              }

              @include v.TABLET {
                max-width: 30rem;
              }
            }

            hr {
              width: 100%;
              
              @include v.DESKTOP {
                display: none;
              }
            }
          }
  
          &.right {
            width: 100%;

            .half-columns {
              @include v.DESKTOP {
                display: flex;
                gap: 3rem;
                
                > * {
                  min-width: calc(50% - 3rem);
                }
              }
            }

            .bottom-btn-container {
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;

              button {
                margin-top: 2rem;
              }
            }
          }
        }
      }
    }
  }
}


