
$BLACK_PRIMARY_DARK: #000000;
$GRAY_PRIMARY_DARK: #666666;
$GRAY_PRIMARY_LIGHT: #cccccc;
$WHITE_PRIMARY_LIGHT: #FFFFFF;

$YELLOW_PRIMARY_LIGHT: #EEDB64;
$PURPLE_PRIMARY_LIGHT: #871144;

/* ====== */
/* MIXINS */
/* ====== */

// Media queries
@mixin MOBILE { 
  @media (max-width: 575.99px) { @content; } 
}

@mixin TABLET { 
  @media (min-width: 576px) and (max-width: 991.99px) { @content; }
}

@mixin DESKTOP { 
  @media (min-width: 992px) { @content; }
}

@mixin MOBILE_AND_TABLET { 
  @media (max-width: 991.99px) { @content; }  
}

@mixin TABLET_AND_DESKTOP { 
  @media (min-width: 576px)  { @content; } 
}

@mixin CONTENT {
  position: relative;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);

  @include MOBILE {
    width: calc(100% - 2rem);
  }

  @include TABLET {
    width: calc(100% - 4rem);
  }

  @include DESKTOP {
    width: calc(100% - 4rem);
    max-width: 120rem;
  }

  @content;
}